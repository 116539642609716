import { Controller } from "stimulus"
import Rails from "@rails/ujs";

class BalanceController extends Controller {
  connect() {
    const BALANCE_EMPLOYEE_PATH = this.data.get('path');
    const EMPTY_AVAILABLE = this.data.get('empty-balance');
    
    Rails.ajax({
      type: "get",
      dataType: 'json',
      url: BALANCE_EMPLOYEE_PATH,
      success: function(response) {
        let available = response.formatted
        if (response.cents == 0) {
          available = EMPTY_AVAILABLE
        }
        document.querySelector(".js-balance").innerHTML = available
      }
    })
  }
}

export default BalanceController
