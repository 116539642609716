// DataTables
let defaultProperties = {
    dom: '<"row mb-3"<"col-sm-12 col-md-6" lB><"col-sm-12 col-md-6" f>>rtip',
    buttons: {
        buttons: [
            { extend: 'excel', text: 'Descargar .xlsx', className: "btn btn-primary btn-table-option ml-4" }
        ]
    },
    language: {
        "decimal": "",
        "emptyTable": "No hay información",
        "info": "Mostrando _START_ - _END_ de _TOTAL_",
        "infoEmpty": "Mostrando 0 - 0 de 0",
        "infoFiltered": "(Filtrado de _MAX_)",
        "infoPostFix": "",
        "thousands": ",",
        "lengthMenu": "Mostrar _MENU_",
        "loadingRecords": "Cargando...",
        "processing": '<div class="spinner-border text-primary dataTables_spinner" role="status"><span class="sr-only">Procesando...</span></div>',
        "search": "Buscar:",
        "zeroRecords": "Sin resultados encontrados",
        "paginate": {
            "first": "Primero",
            "last": "Último",
            "next": "Siguiente",
            "previous": "Anterior"
        }
    }
};

export function initializeDataTable(elem) {
    const $table = $(elem)
    const isRemote = $table.data('remote');
    const remotePath = $table.data('remote-path');
    let dataTable;
    var dom = isRemote ? '<"js-datatable-toolbar row mb-3"<"col-sm-12 col-md-6" l>>rtip' : '<"row mb-3"<"col-sm-12 col-md-6" lB><"col-sm-12 col-md-6" f>>rtip'
    let properties = {...defaultProperties,
        paging: true,
        dom: dom
    };
    if (isRemote) {
        const tableId = $table.attr("id")
        const $filter = $(`.js-datatable-filter[for="${tableId}"]`)
        const $fieldSelector = $filter.find(".search_field")
        const $searchText = $filter.find(".search_text")
        dataTable = setTableAsRemote($table, $fieldSelector, remotePath, 'GET', properties);
        const $toolbar = $(`#${tableId}_wrapper .js-datatable-toolbar`)
        let searchTimeout = null;
        $filter.appendTo($toolbar)
        $filter.removeClass("d-none")
        $searchText.on("keyup", function() {
            clearTimeout(searchTimeout)
            searchTimeout = setTimeout(() => {
                dataTable.search(this.value, false, true).draw();
            }, 250)
        });
        $fieldSelector.change(function() {
            dataTable.search($searchText.val(), false, true).draw();
        })
    } else {
        dataTable = setTableAsLocal($table, properties);
    };

    return dataTable
};

function setTableAsRemote($table, $fieldSelector, path, type, properties) {
    return $table.DataTable({...properties,
        processing: true,
        retrieve: true,
        serverSide: true,
        ajax: {
            url: path,
            type: type,
            data: function(data) {
                // Needed query params
                const query_params = {
                    direction: data.order[0].dir,
                    order: data.columns[data.order[0].column].name,
                    page: Math.ceil(data.start / data.length) + 1,
                    page_size: data.length
                };
                if (data.search.value) {
                    query_params.field = $fieldSelector.val()
                    query_params.term = data.search.value
                }

                return query_params
            },
        },
        createdRow: function(row, data, dataIndex) {
            $(row).attr("data-link", data.url)
        }
    });
};

function setTableAsLocal(elem, properties) {
    return $(elem).DataTable(properties);
};