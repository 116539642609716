import { initializeDataTable } from "nova/datatables";

$(function() {
    ("use strict");

    $(".preloader").fadeOut();

    $(".left-sidebar").hover(
        function() {
            $(".navbar-header").addClass("expand-logo");
        },
        function() {
            $(".navbar-header").removeClass("expand-logo");
        }
    );
    // this is for close icon when navigation open in mobile view
    $(".nav-toggler").on("click", function() {
        $("#main-wrapper").toggleClass("show-sidebar");
        $(".nav-toggler i").toggleClass("ti-menu");
    });
    $(".nav-lock").on("click", function() {
        $("body").toggleClass("lock-nav");
        $(".nav-lock i").toggleClass("mdi-toggle-switch-off");
        $("body, .page-wrapper").trigger("resize");
    });
    $(".search-box a, .search-box .app-search .srh-btn").on(
        "click",
        function() {
            $(".app-search").toggle(200);
            $(".app-search input").focus();
        }
    );

    // ==============================================================
    // Right sidebar options
    // ==============================================================
    $(function() {
        $(".service-panel-toggle").on("click", function() {
            $(".customizer").toggleClass("show-service-panel");
        });
        $(".page-wrapper").on("click", function() {
            $(".customizer").removeClass("show-service-panel");
        });
    });
    // ==============================================================
    // This is for the floating labels
    // ==============================================================
    $(".floating-labels .form-control")
        .on("focus blur", function(e) {
            $(this)
                .parents(".form-group")
                .toggleClass(
                    "focused",
                    e.type === "focus" || this.value.length > 0
                );
        })
        .trigger("blur");

    // ==============================================================
    //tooltip
    // ==============================================================
    //  NOVA INITIAL IMPL
    //$(function() {
    //  $('[data-toggle="tooltip"]').tooltip();
    //});
    // ==============================================================
    //Popover
    // ==============================================================
    //  NOVA INITIAL IMPL
    $(function() {
        $("[data-toggle=\"popover\"]").popover();
    });

    // ==============================================================
    // Perfact scrollbar
    // ==============================================================
    $(function() {
        $(".message-center, .customizer-body, .scrollable").perfectScrollbar({
            wheelPropagation: !0
        });
    });

    // ==============================================================
    // ClipboardJS
    // ==============================================================
    var clipboard = new ClipboardJS(".js-clipboard");
    clipboard.on("success", function(e) {
        const $el = $(e.trigger)
        $el.popover('show');
        setTimeout(() => { $el.popover('hide') }, 2000);
    });


    /*var ps = new PerfectScrollbar('.message-body');
      var ps = new PerfectScrollbar('.notifications');
      var ps = new PerfectScrollbar('.scroll-sidebar');
      var ps = new PerfectScrollbar('.customizer-body');*/

    // ==============================================================
    // Toast
    // ==============================================================
    $(".toast").toast("show");

    // ==============================================================
    // Datepicker
    // ==============================================================
    $(".datepicker").datepicker({
        autoclose: true,
        format: 'dd/mm/yyyy',
        weekStart: 1,
        language: "es"
    }).on("change", function(e) {
        $(this).parents(".form-group").toggleClass("focused", this.value.length > 0)
    });

    // ==============================================================
    // Select Data Spread
    // ==============================================================
    $("select[data-spread-selected-placeholder]").change(function() {
        var $this = $(this);
        var $target = $($this.data("spread-selected-placeholder"));
        var $placeholder = $this.children("option:selected").data("placeholder");
        $target.attr("placeholder", $placeholder);
        $target.focus();
    }).trigger("change");

    // ==============================================================
    // Resize all elements
    // ==============================================================
    $("body, .page-wrapper").trigger("resize");
    $(".page-wrapper")
        .delay(20)
        .show();

    // ==============================================================
    // To do list
    // ==============================================================
    $(".list-task li label").click(function() {
        $(this).toggleClass("task-done");
    });

    // ==============================================================
    // Collapsable cards
    // ==============================================================
    $("a[data-action=\"collapse\"]").on("click", function(e) {
        e.preventDefault();
        $(this)
            .closest(".card")
            .find("[data-action=\"collapse\"] i")
            .toggleClass("ti-minus ti-plus");
        $(this)
            .closest(".card")
            .children(".card-body")
            .collapse("toggle");
    });
    // Toggle fullscreen
    $("a[data-action=\"expand\"]").on("click", function(e) {
        e.preventDefault();
        $(this)
            .closest(".card")
            .find("[data-action=\"expand\"] i")
            .toggleClass("mdi-arrow-expand mdi-arrow-compress");
        $(this)
            .closest(".card")
            .toggleClass("card-fullscreen");
    });
    // Close Card
    $("a[data-action=\"close\"]").on("click", function() {
        $(this)
            .closest(".card")
            .removeClass()
            .slideUp("fast");
    });
    // ==============================================================
    // LThis is for mega menu
    // ==============================================================
    $(document).on("click", ".mega-dropdown", function(e) {
        e.stopPropagation();
    });
    // ==============================================================
    // Last month earning
    // ==============================================================
    //  NOVA INITIAL IMPL
    //  $("#monthchart").sparkline([5, 6, 2, 9, 4, 7, 10, 12], {
    //    type: "bar",
    //    height: "35",
    //    barWidth: "4",
    //    resize: true,
    //    barSpacing: "4",
    //    barColor: "#1e88e5"
    //  });
    //  $("#lastmonthchart").sparkline([5, 6, 2, 9, 4, 7, 10, 12], {
    //    type: "bar",
    //    height: "35",
    //    barWidth: "4",
    //    resize: true,
    //    barSpacing: "4",
    //    barColor: "#7460ee"
    //  });
    //  var sparkResize;

    // ==============================================================
    // This is for the innerleft sidebar
    // ==============================================================
    $(".show-left-part").on("click", function() {
        $(".left-part").toggleClass("show-panel");
        $(".show-left-part").toggleClass("ti-menu");
    });

    // For Custom File Input
    $(".custom-file-input").on("change", function() {
        //get the file name
        var fileName = $(this).val();
        //replace the "Choose a file" label
        $(this)
            .next(".custom-file-label")
            .html(fileName);
    });

    // Datatables
    $('.DataTable').each((i, table) => {
        initializeDataTable(table);
        // For clickable rows
        $(table).delegate("tr[data-link]", "click", function() {
            window.location = $(this).data("link")
        })
    });

    // Issue category
    $(".issue_category").change(function(e) {
        $(".js-issue-category-description").addClass("d-none")
        $(".js-issue-category-description[data-issue-value='" + this.value + "']").removeClass("d-none")
    }).trigger("change");

    // Async forms
    function makeFormAsync(form) {
        const $form = $(form);
        $form.submit(function() {
            var modelName = $form.data("model-name")
            var url = $form.attr("action");
            var data = $(this).serialize();
            var button = $form.find('[type="submit"]').get(0)

            $.rails.disableElement(button)
            $form.find(".form-control-error-msg").empty();
            $form.find(".field_with_errors").removeClass("field_with_errors")
            $.post(url, data)
                .done(function(data) {
                    window.location.href = window.location.href;
                })
                .fail(function(response, data, errorThrown) {
                    $.rails.enableElement(button)
                    var errors = JSON.parse(response.responseText);
                    $.each(Object.keys(errors), function(i, errorField) {
                        var errorSection = $form.find("#" + modelName + "_" + errorField + "_error");
                        errorSection.text(errors[errorField][0]);
                        errorSection.parent(".form-group").addClass("field_with_errors");
                    })
                });
            return false;
        });
    }
    $("[data-async='true']").each((i, el) => {
        makeFormAsync($(el))
    })
});